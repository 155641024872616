$color-primary: rgb(37, 78, 191);
$color-white-primary: rgba(255, 255, 255, .9);
$color-white-secondary: rgba(255, 255, 255, .65);
$offset-y: 4rem;

html {
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 992px) {
    font-size: 16px;
  }
}

body {
  padding-top: $offset-y;
}

.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}

.iconfont {
  font-size: inherit;
  line-height: inherit;
}

.indicator {
  font-size: 1rem;
  font-weight: bold;
  color: $color-primary;
}

.spy-anchor {
  position: absolute;
  width: 100%;
  height: $offset-y;
  top: -$offset-y;
  visibility: hidden;
}

.img-container {
  width: 100%;
  position: relative;
  height: 0;
  overflow: hidden;
  & > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.strong {
  font-size: 1.25em;
  font-weight: 600;
}

.section-title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 768px) {
    font-size: 36px;
  }
}

.section-subtitle {
  font-size: 14px;
  font-weight: 600;
  @media (min-width: 768px) {
    font-size: 18px;
  }
  @media (min-width: 768px) {
    font-size: 24px;
  }
}

.section-desc {
  font-size: 12px;
  font-weight: 400;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 768px) {
    font-size: 20px;
  }
}


.navbar {
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 0 5px #ccc;
  .nav .nav-link {
    color: #212529;
    font-weight: 600;
    font-size: 1rem;
    transition: color .3s;
  }
  .nav .nav-link.active {
    background: transparent;
    color: $color-primary;
  }
  .nav.hover .nav-link.active:hover,
  .nav.hover .nav-link:hover {
    color: $color-primary;
  }

  .nav.hover .nav-link.active {
    color: #212529;
  }

  .navbar-dropdown {
    border-top: 1px solid #e8e8e8;
    .dropdown-item {
      text-align: center;
      line-height: 3rem;
    }
  }
}

.introduct-carousel .carousel-control-next,
.introduct-carousel .carousel-control-prev {
  width: 5%;
}

.introduct-carousel-item {
  padding-bottom: 56.25%;
  cursor: pointer;
}

.jumbotron {
  margin-bottom: 0;
  border-radius: 0;
}

.nav {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.brand {
  height: 3rem;
  cursor: pointer;
}

.subject {
  background-color: rgb(56, 63, 72);
  color: $color-white-secondary;
  font-size: 1.125rem;
  padding: 1.5rem 0;
  .subject-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
}

.product-safety-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-safety-title {
  font-size: 1.75rem;
  font-weight: 600;
}

.product-safety-list {
  max-width: 600px;
  padding-left: 20px;
  margin-top: 2rem;
  font-size: 1rem;
}

.product-safefy-wipe {
  width: 100%;
  max-width: 260px;
}

@media (min-width: 768px) {
  .product-safety-inner {
    flex-direction: row;
    justify-content: space-around;
  }
  .product-safety-title {
    text-align: left;
  }
}

.product-feature {
  background: $color-primary;
  color: $color-white-primary;
}

.product-feature-item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white-primary;
}

.product-feature-item--content {
  padding: 1rem;
  align-self: flex-start;
  max-width: 355px;
}

.product-feature-item--content h4 {
  font-weight: 600;
}

.product-feature-item--img {
  max-width: 35%;
  max-height: 120px;
  // padding: 0 .5rem;
}

.product-problem {
  background-color: #fff;
}

.product-problem-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-problem-detail {
  flex: 1;
}

.product-problem-item {
  font-size: 1.5rem;
}

@media (min-width: 576px) {
  .product-problem-inner {
    flex-direction: row;
  }
}

.solution-compare {
  position: relative;
  .container {
    z-index: 1;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    background-color: $color-primary;
    z-index: 0;
  }
}

.solution-compare-title {
  color: $color-primary;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.solution-compare-picture {
  padding-bottom: 42.86%;
}

.solution-compare-table {
  font-size: 12px;
  display: flex;
  width: auto;
  margin: 0 auto;
  .solution-compare-table-column {
    position: relative;
    margin-right: -1px;
    flex: 1;
  }
  .solution-compare-table-column-inner {
    border: 1px solid rgb(56, 63, 72);
  }
  .solution-compare-table-th {
    background-color: rgb(28, 32, 35);
    color: $color-white-primary;
    display: flex;
    height: 66px;
    flex-direction: column;
    justify-content: center;
    div {
      padding: .25rem .5rem;
      border-top: 1px solid rgb(56, 63, 72);
    }
    div:first-child {
      border-top: 1px solid transparent;
    }
  }
  .solution-compare-table-td {
    border-top: 1px solid rgb(56, 63, 72);
    padding: .25rem .5rem;
    background-color: #fff;
  }
  .solution-compare-table-th > div,
  .solution-compare-table-td > div {
    white-space: nowrap;
  }
  .active .solution-compare-table-column-inner {
    position: relative;
    transform: scale(1);
    transform-origin: bottom center;
    font-weight: 600;
    z-index: 1;
  }
  .active .solution-compare-table-th {
    background-color: $color-primary;
  }
}

@media (min-width: 768px) {
  .solution-compare {
    display: block;
  }
}

@media (min-width: 992px) {
  .solution-compare-table {
    font-size: 16px;
  }
}

.solution-recommend {
  background-color: rgb(222, 222, 222);
}

.solution-recommend-inner {
  display: flex;
  flex-direction: column;
  padding: 4px 0;
}

.solution-recommend-title {
  font-size: 13px;
}

.solution-recommend-subtitle {
  font-size: 12px;
  margin: 0 -0.5rem;
}

@media (min-width: 360px) {
  .solution-recommend-title {
    font-size: 14px;
  }
}

@media (min-width: 576px) {
  .solution-recommend-title {
    font-size: 18px;
  }
  .solution-recommend-subtitle {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .solution-recommend-title {
    font-size: 28px;
  }
  .solution-recommend-subtitle {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .solution-recommend-title {
    font-size: 36px;
  }
  .solution-recommend-subtitle {
    font-size: 24px;
  }
}

.solution-recommend-point-container {
  font-size: 12px;
  max-width: 900px;
  padding: 0 1em;
  display: block;
}

.solution-recommend-point {
  position: relative;
  border-radius: 3em;
  padding: .5em 1.5em;
  text-align: center;
  height: 5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $color-white-primary;
  width: 20em;
  margin: 1em auto;
}

.solution-recommend-point--left .solution-recommend-point:nth-of-type(1) {
  background-color: rgb(27, 173, 180);
}
.solution-recommend-point--left .solution-recommend-point:nth-of-type(2) {
  background-color: rgb(55, 168, 199);
}
.solution-recommend-point--left .solution-recommend-point:nth-of-type(3) {
  background-color: rgb(40, 134, 160);
}
.solution-recommend-point--right .solution-recommend-point:nth-of-type(1) {
  background-color: rgb(111, 137, 180);
}
.solution-recommend-point--right .solution-recommend-point:nth-of-type(2) {
  background-color: rgb(78, 89, 114);
}
.solution-recommend-point--right .solution-recommend-point:nth-of-type(3) {
  background-color: rgb(48, 56, 65);
}

.solution-recommend-point--left .solution-recommend-point--img {
  right: -3em;
}
.solution-recommend-point--right .solution-recommend-point--img {
  left: -3em;
}

.solution-recommend-point--img {
  position: absolute;
  height: 4em;
  width: 4em;
  top: 1em;
  display: none;
}

@media (min-width: 576px) {
  .solution-recommend-point-container {
    font-size: 12px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .solution-recommend-point {
    width: 16em;
  }
  .solution-recommend-point--img {
    display: block;
  }
}
@media (min-width: 768px) {
  .solution-recommend-point-container {
    font-size: 14px;
  }
  .solution-recommend-point {
    width: 18em;
  }
}

@media (min-width: 992px) {
  .solution-recommend-point-container {
    font-size: 16px;
  }
  .solution-recommend-point {
    width: 20em;
  }
}

.effect {
  background-color: rgb(56, 63, 72);
  color: $color-white-primary;
  text-align: center;
}

.effect-inner {
  justify-content: space-between;
  align-items: center;
  z-index: 9;
  padding: 1rem 0;
}

.effect-carousel {
  width: 100%;
  max-width: 290px;
  margin: 0 auto;
  padding: 0 1rem;
}

.effect-item {
  position: relative;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.effect-item--title {
  position: relative;
  font-size: 2.5rem;
}

.effect-item--title:after {
  content: '+';
  position: absolute;
  top: -1.25rem;
  right: -1.5rem;
}

.effect-item--desc {
  font-size: 1.25rem;
}


.effect-covere--image {
  width: 290px;
  height: 345px;
}

.solution-compare-table {
  margin: 0 auto;
}

.solution-compare-table th,
.solution-compare-table td {
  padding: 0 5px;
}

.solution-advantage-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: .5rem;
}
.solution-advantage-subtitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: .5rem;
}

.solution-advantage-ascription {
  padding-bottom: 29.41%;
}

.solution-advantage-image {
  padding-bottom: 100%;
}

.solution-advantage-item {
  line-height: 1.5;
  font-size: 1.125rem;
}

.technology-advantage {
  min-height: 320px;
}

.technology-advantage-primary {
  box-shadow: 0px 0px 10px 0 #254ebf;
  transform: scale(1);
}

.technology-advantage-item {
  margin: 0 auto;
  max-width: 330px;
  height: 100%;
  box-shadow: 0 0 10px 4px #ccc;
  padding: 20px 10%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .technology-advantage-item--title {
    font-size: 16px;
    font-weight: 600;
    color: $color-primary;
  }

  .technology-advantage-item--subtitle {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }

  .technology-advantage-item--footer {
    text-align: center;
    margin-top: 1rem;
    min-height: 3rem;
  }
}

.technology-safety {
  background-color: $color-primary;
  color: $color-white-primary;
  .technology-safety-logo {
    width: 4rem;
    height: 4rem;
  }
  .col, .col-auto {
    padding: 5px;
  }
}

@media (min-width: 768px) {
  .technology-advantage-item {
    min-height: 240px;
    .technology-advantage-item--title {
      font-size: 20px;
    }

    .technology-advantage-item--subtitle {
      font-size: 16px;
    }
  }
  .technology-safety {
    font-size: 1.125rem;
    .technology-safety-logo {
      width: 5rem;
      height: 5rem;
    }
    .col, .col-auto {
      padding: 15px;
    }
  }
}
@media (min-width: 992px) {
  .technology-advantage-item {
    min-height: 280px;
    .technology-advantage-item--title {
      font-size: 22px;
    }

    .technology-advantage-item--subtitle {
      font-size: 18px;
    }
  }
  .technology-advantage-inner {
    position: absolute;
    width: 100%;
  }

  .technology-safety {
    margin-top: 15rem;
    font-size: 1.25rem;
  }
}

// @media (min-width: 1200px) {
//   .technology-safety {
//     margin-top: 11.5rem;
//   }
// }

.technology-point-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .technology-point-item {
    max-width: 32rem;
  }
  .technology-point-item--icon {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    border: 2px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    & > img {
      width: 3rem;
      height: 3rem;
    }
  }
  .media-body {
    margin: 10px;
  }
  @media (min-width: 992px) {
    flex-direction: row;
    .technology-point-item {
      width: 50%;
      text-align: center;
    }
    .technology-point-item:nth-of-type(odd) {
      flex-direction: row-reverse;
    }
    .technology-point-item:last-child {
      flex-direction: column;
      align-items: center;
    }

  }
}


.partner-carousel {
  padding: 0 2rem;
  .partner-carousel-item {
    margin: .5rem;
    padding: 1rem;
    border: 1px solid #e8e8e8;
    border-radius: .25rem;
    & > .img-container {
      padding-bottom: 25%;
    }
  }
}

.technology-ability {
  text-align: center;
  padding: 1rem 2rem;
  .technology-ability-icon {
    font-size: 4rem;
    line-height: 5rem;
    font-weight: 100;
  }
}


.guide-caret {
  position: absolute;
  width: 2.25rem;
  top: 50%;
  transform: translateY(-46%);
}


.contact-us-inner {
  color: $color-white-secondary;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  img {
    max-width: 3rem;
    padding: 0;
    margin-right: 1rem;
  }
  a {
    color: inherit;
  }
  a:hover {
    text-decoration: none;
    color: $color-white-primary;
  }
  .contact-us-item {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
  .contact-us-icon {
    font-size: 16px;
    margin-right: 4px;
    padding: 8px;
    line-height: 1;
    background-color: $color-primary;
    border-radius: 100%;
  }
}

.footer {
  background-color: rgb(2, 2, 2);
  padding: 10px 0;
  font-size: 12px;
  .footer-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #888;
    a {
      color: #888;
    }
    &:hover a {
      color: #aaa;
    }
  }
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  outline: none;
  font-size: 3rem;
  color: #aaa;
}

.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-next:hover {
  background: none;
  color: inherit;
  color: #888;
}

.owl-carousel .owl-nav button.owl-prev {
  left: -2rem;
}

.owl-carousel .owl-nav button.owl-next {
  right: -2rem;
}
.owl-carousel .owl-dots .owl-dot {
  outline: none;
}

.owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
  background: #aaa;
}


[data-aos] {
  visibility: hidden;
}
[data-aos].animated {
  visibility: visible;
}

.logo-img-big {
  width: 100%;
  height: 6rem;
  display: flex;
  justify-items: center;
  align-items: center;
}

.text-primary {
  color: $color-primary!important;
}

.text-success-custom {
  color: #21a03e!important;
  font-weight: 600;
}

img[src=""],
img:not([src]) {
  opacity: 0;
}

.font-weight-bolder-custom {
  font-weight: 600;
}


@-webkit-keyframes scaleIn {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
@keyframes scaleIn {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
.scaleIn {
  -webkit-animation-name: scaleIn;
  animation-name: scaleIn
}


.cursor-pointer {
  cursor: pointer;
}